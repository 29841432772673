<template>
  <div class="course-detail">
    <a-spin :spinning="spinning">
      <div class="spin-content">
        <div class="title">年级</div>
        <ul class="grades">
          <li
            class="grade-item"
            :class="[
              currentGrade.grade == item.grade ? 'grade-item-select' : '',
            ]"
            v-for="item in gradeList"
            :key="item.grade"
            @click="selectGrade(item)"
          >
            {{ item.grade }}
          </li>
        </ul>
        <!-- 上下册 -->
        <a-divider />
        <ul class="volume">
          <li
            class="volume-item"
            :class="[
              currentSemester.semester == item.semester
                ? 'volume-item-select'
                : '',
            ]"
            v-for="item in semesters"
            :key="item.semester"
            @click="selectSemester(item)"
          >
            {{ item.semester }}
          </li>
        </ul>
        <a-divider />
        <!-- 单元 -->
        <div class="title">单元</div>
        <ul class="grades-unit">
          <li
            class="grade-unit-item"
            v-for="item in units"
            :class="[
              currentUnit.unidType == item.unidType ? 'grade-unit-select' : '',
            ]"
            :key="item.unidType"
          >
            <span class="unit-name" @click.stop="selectUnit(item)">{{
              item.unidType
            }}</span>
            <a-popconfirm
              ok-text="确定"
              cancel-text="取消"
              @confirm="refreshUnit(item)"
            >
              <template slot="title">
                <p style="font-weight: bold">单元数据同步提示</p>
                <p style="margin: 0">此操作会将本单元与最新的单元数据同步</p>
                <p style="margin: 0">自己所做的修改会被清除，确认要同步吗？</p>
              </template>
              <a-button type="link" icon="redo"></a-button>
            </a-popconfirm>
            <a-popconfirm
              ok-text="确定"
              cancel-text="取消"
              @confirm="delUnit(item)"
            >
              <template slot="title">
                <p style="font-weight: bold">删除单元提示</p>
                <p style="margin: 0">你确定要删除本单元及其所有课程吗？</p>
                <p style="margin: 0">删除后可以去博览馆重新添加最新课程。</p>
              </template>
              <a-button type="link" icon="delete"></a-button>
            </a-popconfirm>
          </li>
        </ul>
        <a-divider />
        <!-- 章节 -->
        <ul class="unit-child">
          <li class="unit-item" v-for="item in parties" :key="item.LessonId">
            <div class="part">
              <img
                class="arrow"
                src="@/assets/breadcrumb.png"
                alt=""
                srcset=""
              />
              <p class="part-title">{{ item.LessonName }}</p>
              <a-popconfirm
                ok-text="确定"
                cancel-text="取消"
                @confirm="refreshLesson(item)"
              >
                <template slot="title">
                  <p style="font-weight: bold">单课数据同步提示</p>
                  <p style="margin: 0">
                    此操作会将本课与最新的课（含所有课时和附件）数据同步
                  </p>
                  <p style="margin: 0">
                    自己所做的修改或创作会被清除，确认要同步吗？
                  </p>
                </template>
                <a-button type="link" icon="redo"></a-button>
              </a-popconfirm>
              <a-popconfirm
                ok-text="确定"
                cancel-text="取消"
                @confirm="delLesson(item)"
              >
                <template slot="title">
                  <p style="font-weight: bold">删除所有课时提示</p>
                  <p style="margin: 0">你确定要删除本课及其所有课时内容吗？</p>
                  <p style="margin: 0">删除后可以去博览馆重新添加最新课程。</p>
                </template>
                <a-button type="link" icon="delete"></a-button>
              </a-popconfirm>
            </div>
            <ul class="part-child">
              <li
                class="part-child-item"
                v-for="obj in item.MyLessonSection"
                :key="obj.SectionId"
                @click="selectPart({ item: obj, name: item.LessonName })"
              >
                <img src="@/assets/link.png" alt="" srcset="" />
                <label>{{ obj.SectionName }}</label>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  getCourseInfoByCourseName,
  getUnitInfoById,
  refreshMyUnit,
  refreshMyLesson,
  deleteMyLesson,
  deleteMyUnit,
} from "@/api/user";
export default {
  data() {
    return {
      gradeList: [],
      semesters: [],
      currentSemester: {},
      units: [],
      currentGrade: {},
      currentUnit: {},
      parties: [],
      spinning: true,
    };
  },
  mounted() {
    this.$store.commit("M_ADD_BOOK_ROUTER", [
      {
        path: this.$route.fullPath,
        title: this.$route.query.course,
      },
    ]);
    this.requestInfo();
  },
  methods: {
    requestInfo() {
      getCourseInfoByCourseName({ course: this.$route.query.course }).then(
        (res) => {
          console.log("courseInfo", res);
          this.gradeList = res.data.gradeList;
          if (this.gradeList.length > 0) {
            this.selectGrade(this.gradeList[0]);
          } else {
            this.spinning = false;
          }
        }
      );
    },
    /**
     * 选择年级
     */
    selectGrade(item) {
      this.currentGrade = item;
      this.semesters = item && item.semesterList ? item.semesterList : [];
      this.selectSemester(this.semesters[0]);
    },
    /*
     * 选择上下册
     */
    selectSemester(item) {
      this.currentSemester = item;
      this.units = this.currentSemester.unitList;
      this.selectUnit(item.unitList[0]);
    },
    /*
     * 选择单元
     */
    selectUnit(item) {
      this.spinning = true;
      this.currentUnit = item;
      getUnitInfoById({ unitId: item.unidId }).then((res) => {
        console.log("unit part", res);
        this.parties =
          res.data && res.data.lessonList ? res.data.lessonList : [];
        this.spinning = false;
      });
    },
    /**
     * 刷新单元
     */
    refreshUnit(item) {
      console.log(item);
      this.spinning = true;
      refreshMyUnit({ unitId: item.unidId }).then((res) => {
        this.spinning = false;
        this.$message.success(res.msg);
        this.selectUnit(item);
      });
    },
    /**
     * 删除整个单元
     */
    delUnit(item) {
      deleteMyUnit({ unitId: item.unidId }).then((res) => {
        this.spinning = false;
        this.$message.success(res.msg);
        if (res.code == 200) {
          const index = this.units.findIndex((obj) => {
            return obj.unidId == item.unidId;
          });
          this.units.splice(index, 1);
          this.parties = [];
        }
      });
    },
    /**
     * 刷新课程
     */
    refreshLesson(item) {
      this.spinning = true;
      refreshMyLesson({ lessonId: item.LessonId }).then((res) => {
        this.spinning = false;
        this.$message.success(res.msg);
        this.selectUnit(this.currentUnit);
      });
    },
    /**
     * 删除所有课程
     */
    delLesson(item) {
      this.spinning = true;
      deleteMyLesson({ lessonId: item.LessonId }).then((res) => {
        this.spinning = false;
        this.$message.success(res.msg);
        if (res.code == 200) {
          const index = this.parties.findIndex((obj) => {
            return obj.LessonId == item.LessonId;
          });
          this.parties.splice(index, 1);
        }
      });
    },
    /**
     * 选择了课时
     */
    selectPart(item) {
      console.log(item);
      let query = {
        title: `${this.currentGrade.grade}/${this.currentSemester.semester}/${this.currentUnit.unidType}/${item.name}/${item.item.SectionName}`,
        unitId: this.currentUnit.unidId,
        course: this.$route.query.course,
        sectionId: item.item.SectionId,
      };
      this.$router.push({ name: "lessonDesign", query });
    },
  },
};
</script>

<style lang="less">
.course-detail {
  .title {
    margin-top: 46px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium;
    line-height: 22px;
    color: #2a2a31;
    font-weight: bold;
  }
  .grades {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    .grade-item {
      margin-right: 36px;
      margin-top: 36px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2a2a31;
      text-align: center;
      padding: 6px 20px;
      background: white;
      cursor: pointer;
      border-radius: 4px;
    }
    .grade-item-select {
      background: #6e76ff;
      color: white;
    }
  }
  .volume {
    padding: 24px 0;
    display: flex;
    .volume-item {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #2a2a31;
      margin-right: 36px;
      cursor: pointer;
    }
    .volume-item-select {
      color: #6e76ff;
    }
  }
  .grades-unit {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .grade-unit-item {
      margin-right: 36px;
      margin-top: 36px;
      overflow: visible;
      border-radius: 4px;
      .unit-name {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2a2a31;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        display: inline-block;
        padding: 6px 20px;

        &.add-part {
          background-color: #fff;
          border: none;
        }
      }
    }
    .grade-unit-select {
      color: white;
      .unit-name {
        background: #6e76ff;
        color: white;
      }
    }
  }
  .unit-child {
    padding: 0;

    .grade-unit-item {
      margin-right: 36px;
      margin-top: 36px;
      overflow: visible;
      border-radius: 4px;
      .unit-name {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2a2a31;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        display: inline-block;
        padding: 6px 20px;

        &.add-part {
          background-color: #fff;
          border: none;
        }
      }
    }

    .unit-item {
      padding-top: 30px;
      padding-left: 0px;
      .part {
        display: flex;
        align-items: center;
        .arrow {
          width: 8px;
          height: 8px;
          object-fit: contain;
        }
        .part-title {
          margin: 0;
          padding-left: 8px;
          font-size: 16px;
          color: #2a2a31;
          font-weight: bold;
        }
      }
      .part-child {
        padding: 0;
        margin-left: 16px;
        display: flex;
        .part-child-item {
          font-size: 14px;
          padding: 4px 34px 4px 13px;
          background: white;
          margin-right: 14px;
          margin-top: 15px;
          cursor: pointer;
          label {
            padding-left: 13px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>